.header-container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* center items horizontally */
    margin: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.animation-container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* center items horizontally */
    align-items: center;
    padding-bottom: 1rem;

}

.lottie-animation {
    display: flex;
    justify-content: center;
    align-items: center;
}

.animated-text {
    display: inline;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .header-container {
        margin: 0rem;
    }
}

  
  